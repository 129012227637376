import { cn } from '@/utils'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from '@nextui-org/modal'

import React from 'react'

type FormModalProps = {
  asForm?: boolean
  formId?: string
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}

export type UIModalProps = Omit<
  ModalProps,
  'backdrop' | 'motionProps' | 'title'
> & {
  title: string | React.ReactNode
  footerClassName?: string
  bodyClassName?: string
  headerClassName?: string
  contentClassName?: string
  footer?: React.ReactNode | ((onClose: () => void) => React.ReactNode)
  children: React.ReactNode | ((onClose: () => void) => React.ReactElement)
} & FormModalProps

const formModalClassNames = {
  base: 'sm:my-[6vh] my-[6vh] max-h-[calc(100%_-_12vh)]',
  footer: 'border-t-[0.5px] border-divider/15 dark:border-divider/10',
  // backdrop: 'bg-overlay/20 dark:bg-overlay/30',
}

const FormWrapper = ({
  children,
  ...props
}: {
  children: React.ReactNode
} & React.FormHTMLAttributes<HTMLFormElement>) => (
  <form noValidate className="contents" {...props}>
    {children}
  </form>
)

const UIModal = ({
  asForm = false,
  formId,
  onSubmit,
  title,
  footer,
  footerClassName,
  bodyClassName,
  headerClassName,
  contentClassName,
  children,
  ...moreModalProps
}: UIModalProps) => {
  const Wrapper = asForm ? FormWrapper : React.Fragment

  const wrapperProps = asForm ? { id: formId, onSubmit: onSubmit } : {}

  return (
    <Modal
      radius="md"
      {...moreModalProps}
      placement={asForm ? 'top' : moreModalProps.placement}
      classNames={{
        ...(asForm && formModalClassNames),
        base: cn([
          'shadow-modalLight dark:shadow-modalDark border-[0.5px] border-divider/15 dark:border-divider/10 sm:mx-6 sm:my-16 my-16',
          moreModalProps.scrollBehavior === 'inside' &&
            'max-h-[calc(100%_-_8rem)]', // TODO: Remove this once we update the core NextUI Modal component
          asForm && formModalClassNames.base,
        ]),
        wrapper: [
          'h-[--visual-viewport-height] transition-height duration-300',
          moreModalProps.placement === 'center' && 'items-center', // TODO: Remove this once we update the core NextUI Modal component
        ],
      }}
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: 'easeOut',
            },
          },
          exit: {
            y: -20,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: 'easeIn',
            },
          },
        },
      }}
    >
      <ModalContent className={contentClassName}>
        {onClose => (
          <Wrapper {...wrapperProps}>
            <ModalHeader className={headerClassName}>{title}</ModalHeader>
            <ModalBody className={bodyClassName}>
              {typeof children === 'function' ? children(onClose) : children}
            </ModalBody>
            {footer && (
              <ModalFooter className={cn('py-3', footerClassName)}>
                {typeof footer === 'function' ? footer(onClose) : footer}
              </ModalFooter>
            )}
          </Wrapper>
        )}
      </ModalContent>
    </Modal>
  )
}

UIModal.displayName = 'Docsum.UIModal'

export default UIModal
